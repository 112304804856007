import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  CrmTranslateModule,
  defaultTranslateConfig,
} from 'common-module/translate';
import { CrmEndpointModule } from 'common-module/endpoint';
import { CrmAppConfig, CrmModuleConfigToken } from 'common-module/core';
import { en_US, NZ_DATE_CONFIG, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import skLocale from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient } from '@angular/common/http';
import { LayoutModule } from '@app/core/layout/layout.module';
import { provideServiceWorker } from '@angular/service-worker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { UsersModule } from '@app/core/users/users.module';
import { CrmDefaultPaginationToken } from 'common-module/table';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { endpointModuleConfiguration } from '../environments/environment';

import { authModuleConfig } from './config/auth.config';
import { routes } from './app.routes';
import { crmAppConfig } from './config/app.config';

registerLocaleData(en);
registerLocaleData(skLocale);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(NzModalModule),
    importProvidersFrom(LayoutModule),
    importProvidersFrom(UsersModule),
    provideRouter(routes),
    importProvidersFrom(CrmTranslateModule.forRoot(defaultTranslateConfig)),
    importProvidersFrom(CrmEndpointModule.forRoot(endpointModuleConfiguration)),
    {
      provide: CrmModuleConfigToken,
      useValue: authModuleConfig,
      multi: true,
    },
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideCharts(withDefaultRegisterables()),
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
    {
      provide: CrmAppConfig,
      useValue: crmAppConfig,
    },
    {
      provide: CrmDefaultPaginationToken,
      useValue: {
        limit: 20,
        // NOTE: fix of pagination query params parsing in table provider - its based on default pagination keys, therefore skip is ignored if not present in default pagination
        skip: 0,
      },
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
